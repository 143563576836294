<template>
  <div>
    <!-- Alert: No item found -->
    <template>
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
  import { BRow, BCol } from 'bootstrap-vue'
  import UserViewUserInfoCard from './UserViewUserInfoCard.vue'

  export default {
    components: {
      BRow,
      BCol,

      // Local Components
      UserViewUserInfoCard,
    },
    data() {
      return {}
    },
  }
</script>

<style></style>
