<template>
  <div v-if="profile">
    <role-add
      :is-sidebar-active.sync="isAddNewRoleSidebarActive"
      :is-readonly="isAddNewRoleSidebarInDisplayMode"
      :value.sync="activeRoleRecord"
      :state.sync="userLoading"
      :edit-tab="editRecord"
      @update:profile="onUpdateSave"
    />

    <user-add
      :is-sidebar-active.sync="isEditCurrentUserSideBarActive"
      :is-readonly="false"
      :user-type-options="userTypeOptions"
      :auth-method-options="authMethodOptions"
      :value.sync="userData"
    />

    <process-add
      :is-sidebar-active.sync="isAddNewProcessSidebarActive"
      :is-readonly.sync="isAddNewProcessSidebarInDisplayMode"
      :value.sync="activeProcessRecord"
      :edit-tab="editProcessRecord"
    />

    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col>
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="profile.avatar"
              :text="avatarText(profile.name)"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ profile.name }}
                </h4>
                <span class="card-text">
                  {{ profile.peopleHandle }}
                </span>
              </div>
              <div v-if="isOwner" class="d-flex flex-wrap">
                <b-button variant="primary" @click="editCurrentUser">
                  Edit
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="d-flex justify-content-around flex-column">
          <table class="info-table">
            <tr>
              <th />
              <th />
            </tr>
            <tr>
              <td>
                <feather-icon icon="UserIcon" size="16" />
                <span style="font-weight: bold"> Username </span>
              </td>
              <td>
                <span>{{ profile.name }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <feather-icon icon="StarIcon" size="16" />
                <span style="font-weight: bold"> User Type </span>
              </td>
              <td>
                <span>{{ profile.userType }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <feather-icon icon="KeyIcon" size="16" />
                <span style="font-weight: bold"> Auth Method </span>
              </td>
              <td>
                <span>{{ profile.authMethod }}</span>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <h1 class="card-title">
Description
</h1>
      <Quill-Editor
        :description.sync="profile.description"
        :is-readonly="true"
      />
    </b-card>

    <b-card>
      <h1 class="card-title">
        Roles & Processes
        <h6
          v-if="!profile.roles || profile.roles.length === 0"
          style="color: red"
        >
          No roles assigned
        </h6>
      </h1>
      <b-card
        v-for="(role, index) in profile.roles"
        :key="role.roleHandle"
        style="text-align: center"
        class="card-custom"
      >
        <div class="card-header btn-grp">
          <b-button
            v-b-toggle
            class="tab-btn"
            variant="light"
            block
            :href="`#collapse-${index}`"
            @click.prevent="isVisible(index)"
          >
            <b-row>
              <div class="icon-container">
                <feather-icon
                  :icon="values[index] ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  class="icon-left"
                  size="16"
                  @click="dropIco"
                />
              </div>
              <b-col cols="10" class="truncate role-title">
                <span>{{ '  ' + role.roleHandle + ': ' + role.name }}</span>
              </b-col>
              <b-col cols="2" class="link_" @click="handleOpenRole(role)">
                <feather-icon
                  class="cursor-pointer"
                  icon="ExternalLinkIcon"
                  size="16"
                />
              </b-col>
            </b-row>
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon tab-btn-right"
            @click="handleOpenRole(role)"
          >
            <feather-icon
              icon="SidebarIcon"
              size="16"
            />
          </b-button> -->
        </div>
        <div class="card-body">
          <b-collapse :id="`collapse-${index}`" v-model="values[index]" visible>
            <b-table
              v-if="rolesItems(role)"
              show-empty
              hover
              empty-text="No matching records found"
              :items="rolesItems(role)"
              mb-0
              :fields="fields"
              table-variant="light-secondary"
              class="custom-table"
              fixed
              small
              @row-clicked="displayProcess"
            >
              <template #cell(name)="data">
                <div class="name">
                  {{ data.item.processHandle }} : {{ data.item.name }}
                </div>
              </template>

              <template #cell(status)="data">
                <div class="">
                  <b-badge
                    v-if="data.item.name !== 'Not Assigned'"
                    pill
                    :variant="
                      data.item.owned && data.item.assigned
                        ? 'primary'
                        : data.item.assigned
                          ? 'dark'
                          : 'success'
                    "
                  >
                    {{
                      data.item.owned && data.item.assigned
                        ? 'Owner & Assignee'
                        : data.item.assigned
                          ? 'Assignee only'
                          : 'Owner only'
                    }}
                  </b-badge>
                </div>
              </template>
            </b-table>
            <div v-else
class="msg"
>no Process assigned</div>
          </b-collapse>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard, BButton, BRow, BCol, VBToggle, VBVisible, BAvatar, BTable, BCollapse, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapState } from 'vuex'
import UserAdd from '../UserAdd.vue'
import ProcessAdd from '../../process/ProcessAdd.vue'
import RoleAdd from '../../role/RoleAdd.vue'
import QuillEditor from '../../shared/QuillEditor.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    UserAdd,
    ProcessAdd,
    BAvatar,
    BTable,
    RoleAdd,
    BCollapse,
    BBadge,
    QuillEditor,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-visible': VBVisible,
    Ripple,
  },
  data() {
    return {
      stickyHeader: true,
      noCollapse: false,
      userTypeOptions: [
        { label: 'Admin', value: 'Admin' },
        { label: 'Manager', value: 'Manager' },
        { label: 'User', value: 'User' },
      ],
      authMethodOptions: [
        { label: 'Email / Password', value: 'Email' },
        { label: 'Provider (e.g., Google, Microsoft)', value: 'Oauth' },
        { label: 'No Auth', value: 'No' },
      ],
      isAddNewProcessSidebarActive: false,
      isAddNewProcessSidebarInDisplayMode: false,
      activeRecord: null,
      isAddNewRoleSidebarActive: false,
      isAddNewRoleSidebarInDisplayMode: false,
      activeRoleRecord: null,
      activeProcessRecord: null,
      isEditCurrentUserSideBarActive: false,
      avatarText,
      userData: null,
      collapseType: 'margin',
      collType: false,
      show: 'open',
      fields: [
        // { key: 'ID' },
        { key: 'Name', label: 'Process Name', class: 'table-th name-th' },
        { key: 'status', label: 'OWNER/ASSIGNEE', class: 'table-th status-th' },
      ],
      localUser: '',
      values: [],
      profile: this.getDefaultProfile(),
      disabled: true,
      userLoading: false,
      isCurrentUserSideBarActive: false,
    }
  },
  computed: {
    ...mapState('user', [
      'currentUser',
      'user',
    ]),
    ...mapState('role', ['currentUserRoles']),
    ...mapState('process', [
      'currentUserProcesses',
      'isLoading',
    ]),
    rolesProcessOptions() {
      return this.currentUserRoles.nodes.map(item => ({
        label: item.name,
        value: item.id,
        ...item,
      }))
    },
    currentRouteName() {
      return this.$route.name
    },
    isOwner() {
      if (this.currentRouteName === 'my-profile') {
        return true
      }
      return false
    },
    routerParam() {
      return this.$route.params.id
    },
  },
  watch: {
    user() {
      const id = Number(this.routerParam)
      if (id !== this.currentUser.id) {
        this.profile = this.user
      }
      this.user.roles.forEach((el, index) => {
        this.values[index] = true
      })
    },
    currentUser() {
 
      const id = Number(this.routerParam)
      if (this.currentUser.id === id) {
        this.profile = this.currentUser
      }
    },
    routerParam() {
      this.setProfile()
    },
  },
  mounted() {
    this.fetchCurrentUserIfNotLoaded()
    this.setProfile()
    this.getCurrentUserProcesses({
      q: '',
      offset: 0, 
      limit: 1000,
      sortBy: 'id', 
      isDesc: false,
    })
  },
  methods: {
    ...mapActions('user', [
      'fetchCurrentUserIfNotLoaded',
      'getUser',
    ]),
    ...mapActions('role', [
      'refetchCurrentUserRolesIfNotLoaded',
      'getRoles',
    ]),
    ...mapActions('process', [
      'getCurrentUserProcesses',
    ]),
    isVisible(index) {
      const elementClass = document.getElementById(`collapse-${index}`).classList
      if (elementClass.value.includes('show')) {
        this.values[index] = true
      } else {
        this.values[index] = false
      }
    },
    setProfile() {
      const id = Number(this.routerParam)
      if (id === this.currentUser.id) {
        this.refetchCurrentUserRolesIfNotLoaded()
        this.profile = this.currentUser
      } else {
        this.getUser({
          id,
        }).then(() => {
          Object.assign(this.profile, this.user)
        })
      }
    },
    getDefaultProfile() {
      return {
        name: '',
        peopleHandle: '',
        description: '',
        userType: null,
        authMethod: null,
        password: null,
        roleIds: null,
        receiveWeeklyReminders: true,
      }
    },
    rolesItems(role) {
      if (role.assignedProcesses?.length === 0 && role.process?.length === 0) {
        return false
      }

      const allProcesses = this.combineArrays(role.assignedProcesses, role.process).filter(item => !!item)

      const ids = []
      const processes = []

      allProcesses.forEach(item => (
        ids.includes(item.id) ? null : ids.push(item.id)
      ))

      allProcesses.forEach(item => {
        const found = processes.find(({ id }) => id === item.id)
        if (!found) {
          processes.push(item)
        }
      })

      processes.forEach((element, index) => {
        const owned = role.process?.find(pr => pr.id === element.id)
        const assigned = role.assignedProcesses?.find(aPr => aPr.id === element.id)
        if (owned) {
          processes[index].owned = 'OWNED'
        }
        if (assigned) {
          processes[index].assigned = 'ASSIGNED'
        }
      })
      
      return processes
    },
    combineArrays(arr1, arr2) {
      if (arr1 && arr2) {
        const allArr = []
        arr1.forEach(el => {
          const arr2El = arr2.find(item => item.id === el.id)
          if (arr2El) {
            allArr.push({ ...el, arr2El })
          } else {
            allArr.push(el)
          }
        })
        arr2.forEach(el => {
          const arr2El = allArr.find(item => item.id === el.id)
          if (!arr2El) {
            allArr.push(el)
          }
        })
        return allArr
      }
      return arr1.concat(arr2)
    },
    editCurrentUser() {
      const recordToEdit = { ...this.currentUser }
      recordToEdit.roles = this.currentUserRoles
      this.userData = { ...recordToEdit }
      this.isEditCurrentUserSideBarActive = true

      this.isAddNewProcessSidebarActive = false
      this.isAddNewProcessSidebarInDisplayMode = false
    },
    dropIco() {
      this.dropState = !this.dropState
    },
    editProcessRecord(activeRecord) {

      const selectedId = activeRecord.id

      this.currentUserProcesses.nodes.forEach((process) => {
        if(process.id === selectedId) {
          this.activeProcessRecord= {...process}
          return
        }
      })

      this.isAddNewProcessSidebarActive = true
      this.isAddNewProcessSidebarInDisplayMode = false
    },
    displayProcess(activeRecord) {
      this.editProcessRecord(activeRecord)
      this.isAddNewProcessSidebarInDisplayMode = true
    },
    changeDesc(value) {
      this.user.description = value
    },
    handleState() {
      this.userLoading = false
    },
    handleOpenRole(value) {
      this.editRecord(value)
      this.isAddNewRoleSidebarInDisplayMode = true
    },
    handleOpenUser(id) {
      this.userLoading = true
      this.getUser({
        id,
      }).then(() => {
        this.userLoading = false
        this.isCurrentUserSideBarActive = true
        this.isAddNewRoleSidebarActive = true
      })
    },
    handleOpenProcess(activeProcess) {
      this.activeProcessRecord = activeProcess
      this.isProcessSidebarActive = true
    },
    editRecord(activeRecord) {
      this.activeRoleRecord = activeRecord
      this.isAddNewRoleSidebarActive = true
      this.isAddNewRoleSidebarInDisplayMode = false
    },
    onUpdateSave() {
      this.fetchCurrentUserIfNotLoaded()
      this.setProfile()
      this.currentUser.roles.forEach((el, index) => {
        this.values[index] = true
      })
    },
  },
}
</script>

<style lang="scss" >
.info-table{
  tr > td{
      padding-bottom: 1em;
  }
}

th.status-th{
 text-align: center;
}

.table-th{
  padding: 10.08px 0px!important;
  div{
    margin-left: 5px;
  }
  span{
    margin-left: 5px;
  }
  .name{
    text-align: left;
  }
}
td.table-th{
  text-align: center;
  .badge{
    text-align: center!important;
  }
}

.card-title{
  text-align: left;
}

  .card-custom{
  min-width: 100%!important;
  align-self: center;
}

  .card-custom > .card-body{
  box-shadow: 0 4px 24px 0 rgba(34,41,47,.1)!important;
  border-radius: 5px;
  padding: 5px;
    .card-body{
      padding: 0px;
    }
}

  .card-custom > .card-header{
  padding: 0px;
}

.table{
  color: black;
}
.msg{
  color:red;
  text-align: center;
}
.tab-btn{
  text-align: center;
  color: black!important;
  background-color: white!important;
  border: none;
  animation-play-state: paused!important;
  box-shadow: none!important;
}

.btn-grp{
  width: 100%;
  padding: 0px!important;
}

.icon-left{
  float: left;
}

.custom-table{
  padding: 10px!important;
}
.link_{
    padding-left: 0px!important;
  }
</style>
